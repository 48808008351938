import React, { useEffect, useRef, useState } from "react";
import {
    BsClipboard,
    BsHandThumbsDown,
    BsHandThumbsDownFill,
    BsHandThumbsUp,
    BsHandThumbsUpFill,
} from "react-icons/bs";
import { AiFillCloseSquare, AiOutlineUser } from "react-icons/ai";
import {
    renderMessageWithLinks,
    sendVote,
    getCertaintyColor,
} from "@utils/messages.utils";
import logo from "@assets/img/gpt-logo.svg";

// Updated ChatData interface
export interface ChatData {
    id: number;
    message: string;
    certainty: number;
    vote: number;
    tab_id: number;
    feedback?: {
        relevantie: number;
        nauwkeurigheid: number;
        volledigheid: number;
        duidelijkheid: number;
    };
}

interface ChatMessagesProps {
    chats: ChatData[];
    waitingForReply: boolean;
    streamedReply: string;
}

interface VoteMessage {
    value: number;
    message_id: number;
    tab_id: number;
    feedback: {
        relevantie: number;
        nauwkeurigheid: number;
        volledigheid: number;
        duidelijkheid: number;
    };
}

export const Messages: React.FC<ChatMessagesProps> = ({
    chats,
    waitingForReply,
    streamedReply,
}) => {
    const [localChats, setLocalChats] = useState<ChatData[]>(chats);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [voteFormOpen, setVoteFormOpen] = useState<boolean>(false);
    const [voteMessage, setVoteMessage] = useState<VoteMessage | undefined>();
    const [voteReason, setVoteReason] = useState<string>("");

    useEffect(() => {
        setLocalChats(chats);
    }, [chats]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    }, [chats]);

    const handleVote = (value: number, message_id: number, tab_id: number) => {
        setVoteMessage({
            value,
            message_id,
            tab_id,
            feedback: {
                relevantie: 3,
                nauwkeurigheid: 3,
                volledigheid: 3,
                duidelijkheid: 3,
            },
        });
        setVoteFormOpen(true);
    };

    const submitVote = (voteMessage: VoteMessage | undefined) => {
        if (!voteMessage) {
            return;
        }
        const { value, message_id, tab_id, feedback } = voteMessage;

        // Combine feedback and voteReason into a single string
        const combinedReason = JSON.stringify({
            userReason: voteReason,
            feedback: feedback,
        });

        sendVote(value, message_id, tab_id, combinedReason).then(() => {
            setVoteReason("");
            setVoteMessage(undefined);
        });

        const updatedChats = localChats.map((chat) =>
            chat.id === message_id ? { ...chat, vote: value, feedback } : chat
        );
        setLocalChats(updatedChats);
    };

    return (
        <div className="overflow-y-scroll h-full" ref={chatContainerRef}>
            {localChats.length > 0 &&
                localChats.map((chat, index) => (
                    <div
                        className={`w-full group ${
                            index % 2 !== 0
                                ? "dark:bg-[rgba(68,70,84)] border-y-[1px] dark:border-gray-600 bg-gray-100"
                                : "dark:bg-primaryDark"
                        }`}
                        key={index}
                    >
                        <div
                            className={`flex dark:text-gray-300 text-gray-900 p-4 gap-4 text-base md:gap-6 md:max-w-2xl lg:max-w-[46rem] xl:max-w-4xl md:py-6 lg:px-0 m-auto ${
                                index % 2 !== 0
                                    ? "dark:bg-[rgba(68,70,84)] bg-gray-100"
                                    : "dark:bg-primaryDark"
                            }`}
                        >
                            <div className="flex-shrink-0 flex flex-col relative items-end">
                                <div className="w-[30px] shadow-sm">
                                    {index % 2 !== 0 ? (
                                        <img
                                            className={`bg-white w-[30px] h-[30px] rounded-sm px-1 py-1 mt-1 ${getCertaintyColor(
                                                chat.certainty
                                            )}`}
                                            src={logo}
                                            alt=""
                                        />
                                    ) : (
                                        <div className="flex items-center w-[30px] h-[30px] text-white justify-center bg-gray-500 rounded-sm px-1 py-1 mt-1 ">
                                            <AiOutlineUser />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {waitingForReply &&
                            index === localChats.length - 1 ? (
                                <>
                                    <div className="bouncing-loader flex justify-start pt-4">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div
                                        className="text-left"
                                        style={{ whiteSpace: "pre-wrap" }}
                                    >
                                        {streamedReply}
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <div className="w-full flex gap-6">
                                        {index % 2 !== 0 &&
                                        chat.message.includes(
                                            "Er is iets misgegaan, probeer het opnieuw."
                                        ) ? (
                                            <div className="py-2 px-4 bg-red-500 rounded-md border-2 border-red-500 bg-opacity-20">
                                                {chat.message}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                }}
                                                className="text-left"
                                            >
                                                {renderMessageWithLinks(
                                                    chat.message
                                                )}
                                            </div>
                                        )}
                                        <div>
                                            {index % 2 !== 0 && (
                                                <div className="flex w-full justify-end gap-3">
                                                    <BsClipboard
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                chat.message
                                                            );
                                                        }}
                                                    />
                                                    {chat.vote === 1 ? (
                                                        <BsHandThumbsUpFill />
                                                    ) : (
                                                        <BsHandThumbsUp
                                                            className={
                                                                chat.vote === 0
                                                                    ? "cursor-pointer"
                                                                    : ""
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    chat.vote ===
                                                                    0
                                                                )
                                                                    handleVote(
                                                                        1,
                                                                        chat.id,
                                                                        chat.tab_id
                                                                    );
                                                            }}
                                                        />
                                                    )}
                                                    {chat.vote === -1 ? (
                                                        <BsHandThumbsDownFill />
                                                    ) : (
                                                        <BsHandThumbsDown
                                                            className={
                                                                chat.vote === 0
                                                                    ? "cursor-pointer"
                                                                    : ""
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    chat.vote ===
                                                                    0
                                                                )
                                                                    handleVote(
                                                                        -1,
                                                                        chat.id,
                                                                        chat.tab_id
                                                                    );
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}

            {voteFormOpen && voteMessage && (
                <div className="fixed w-screen h-screen inset-0 bg-black bg-opacity-60 flex justify-center items-center">
                    <div className="bg-white w-1/3 dark:bg-secondaryDark rounded-md p-4 flex flex-col gap-4">
                        <div className="flex w-full justify-between">
                            <h2 className="text-xl font-bold dark:text-gray-200">
                                Geef feedback
                            </h2>
                            <div
                                className="cursor-pointer"
                                onClick={() => {
                                    setVoteFormOpen(false);
                                    setVoteReason("");
                                }}
                            >
                                <AiFillCloseSquare size={24} color="#FF6961" />
                            </div>
                        </div>

                        {(
                            [
                                "relevantie",
                                "nauwkeurigheid",
                                "volledigheid",
                                "duidelijkheid",
                            ] as const
                        ).map((metric) => (
                            <div key={metric} className="flex flex-col gap-2">
                                <label className="text-gray-700 dark:text-gray-200 capitalize">
                                    {metric}
                                </label>
                                <div className="flex justify-between">
                                    {[1, 2, 3, 4, 5].map((value) => (
                                        <button
                                            key={value}
                                            className={`w-10 h-10 rounded-full ${
                                                voteMessage.feedback[metric] ===
                                                value
                                                    ? "bg-blue-500 text-white"
                                                    : "bg-gray-200 dark:bg-gray-700"
                                            }`}
                                            onClick={() =>
                                                setVoteMessage({
                                                    ...voteMessage,
                                                    feedback: {
                                                        ...voteMessage.feedback,
                                                        [metric]: value,
                                                    },
                                                })
                                            }
                                        >
                                            {value}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ))}

                        <div className="flex flex-col gap-2">
                            <label
                                className="text-gray-700 dark:text-gray-200"
                                htmlFor="reason"
                            >
                                Reden voor feedback (optioneel)
                            </label>
                            <textarea
                                className="border-2 rounded-md w-full bg-secondaryDark focus:ring-gray-400 ring-1 ring-gray-600 focus:ring-2 p-4 text-gray-200 border-none outline-none"
                                name="reason"
                                id="reason"
                                rows={3}
                                value={voteReason}
                                onChange={(e) => setVoteReason(e.target.value)}
                            />
                        </div>

                        <button
                            className="text-white bg-secondary rounded-md py-2 px-4"
                            onClick={() => {
                                setVoteFormOpen(false);
                                submitVote(voteMessage);
                            }}
                        >
                            Verstuur feedback
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
