import React, { useEffect } from "react";
import { SummarisedArticle } from "@customTypes/timeline.types";
import { BsThreeDots } from "react-icons/bs";
import { TimelineArticle } from "@components/Timeline/TimelineArticle";

interface TimelineProps {
    groupedTimelineData: { [year: string]: SummarisedArticle[] };
    collapsedYears: number[];
    toggleYearCollapse: (year: number) => void; // Update the function signature to include tabIndex
}

export const Timeline: React.FC<TimelineProps> = ({
    groupedTimelineData,
    collapsedYears,
    toggleYearCollapse,
}) => {
    return (
        <div className="mx-auto justify-center p-4 w-2/3  ">
            {Object.entries(groupedTimelineData).map(([year, entries], id) => (
                <div className="flex flex-col" key={id}>
                    <h1
                        className="font-bold text-xl cursor-pointer"
                        onClick={() => toggleYearCollapse(parseInt(year))} // Pass the activeTab to toggleYearCollapse
                    >
                        {year}
                    </h1>
                    <div className="px-4">
                        <div className="border-l-2 border-gray-500 w-full ">
                            <div className="p-4 flex flex-col gap-8">
                                {!collapsedYears.includes(parseInt(year)) ? (
                                    entries.map(
                                        (
                                            { summary, title, date, keywords },
                                            id,
                                        ) => (
                                            <TimelineArticle
                                                summary={summary}
                                                title={title}
                                                date={date}
                                                keywords={keywords}
                                                key={id} // Add a unique key for each timeline article
                                            />
                                        ),
                                    )
                                ) : (
                                    <div className="flex items-center justify-center bg-slate-200 dark:bg-gray-200 text-gray-700 p-2 w-10 h-4">
                                        <BsThreeDots />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
