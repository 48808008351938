import React from "react";
import { DocumentLink } from "./DocumentLink";

export const DocumentsBranch: React.FC<{
    name: string;
    type: string;
    children: React.ReactNode;
}> = ({ name, type, children }) => {
    return (
        <div className="space-y-4">
            <h1 className="dark:text-gray-200 text-gray-900 font-bold text-xl cursor-pointer">
                <span className="dark:text-gray-500">{name}</span>
                &nbsp;&nbsp;/&nbsp;&nbsp;{type}
            </h1>

            <div className="space-y-4">{children}</div>
        </div>
    );
};
