import React from "react";
import { AiOutlineLink, AiOutlineFileText } from "react-icons/ai";

export const DocumentLink: React.FC<{
    title: string;
    href: string;
}> = ({ title, href }) => {
    return (
        <div className="flex space-x-2">
            <h2 className="flex space-x-2 dark:text-gray-200 text-gray-900 text-l cursor-pointer">
                <AiOutlineFileText size={25} />
                <a target="_blank" href={href}>
                    {title}
                </a>
            </h2>
        </div>
    );
};
