import axios from "axios";

/**
 * Axios instance for making requests to the backend.
 * @type {AxiosInstance}
 */
const instance = axios.create({
    baseURL: "/api", //change to http://localhost:5001/api for local development
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
    },
});

/**
 * Axios interceptor for handling 401 and 422 responses.
 * Removes the token if a 401 or 422 response is received.
 * @param {Object} error - The error object.
 */
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401 || error.response.status === 422) {
            localStorage.removeItem("token");
        }
    },
);

/**
 * Axios interceptor for adding the token to the request headers.
 * @param {Object} config - The config object.
 * @returns {Object} The config object.
 * @throws {Error} The error object.
 */
instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default instance;
