import { useEffect, useState } from "react";
import {
    AiOutlineCloudDownload,
    AiOutlineSetting,
    AiOutlineDownload,
    AiOutlineUserAdd,
} from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ColorModeToggle } from "./ColorModeToggle";
import { sidebarItems } from "@utils/navigation.utils";
import { AiOutlineUser } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { logout, clientSideLogout } from "@utils/auth.utils";
import axios from "@includes/axios";

const logo = require("../assets/img/logo.svg").default as string;

interface SideNavProps {
    isDarkMode: boolean;
    setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
    username: string;
}

export const SideNav: React.FC<SideNavProps> = ({
    isDarkMode,
    setIsDarkMode,
    username,
}) => {
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const [settingsExpanded, setSettingsExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (isDarkMode) {
            localStorage.theme = "dark";
        } else {
            localStorage.theme = "light";
        }
    }, [isDarkMode]);

    const handleLogout = async () => {
        await logout();
        clientSideLogout();
    };

    const handleReportExport = async () => {
        const resp = await axios.get("/report", { responseType: "blob" });
        console.log(resp);
        const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
        const pdfURL = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfURL;
        link.download = "report.pdf";
        link.click();
    };

    const handleMessagesExport = async () => {
        const resp = await axios.get("/data");
        const jsonString = `data:text/csv;chatset=utf-8,${encodeURIComponent(
            resp.data
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "messages.csv";
        link.click();
    };

    const handleDocumentsExport = async () => {
        const resp = await axios.get("/document-suggestions");
        const jsonString = `data:text/csv;chatset=utf-8,${encodeURIComponent(
            resp.data
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "suggestions.csv";
        link.click();
    };

    const handleCreateUser = () => {
        navigate("/create-user");
        setSettingsExpanded(false);
    };

    return (
        <aside className="flex flex-col w-72 h-screen px-2 py-4 overflow-y-auto  bg-secondaryDark">
            <div className="py-4">
                <img src={logo} className="mx-auto w-1/2 " alt="" />
            </div>
            <div className="flex flex-col justify-between flex-1 mt-6">
                <nav className="mx-2 space-y-6 flex flex-col h-full">
                    <div className="space-y-3">
                        <label className="sidebar-label text-gray-100">
                            Views
                        </label>
                        {sidebarItems.map((item, index) => (
                            <Link
                                key={index}
                                className={
                                    location === item.path
                                        ? "nav-item-active"
                                        : "nav-item"
                                }
                                to={item.path}
                            >
                                {item.icon}
                                <span className="mx-2 text-sm text-gray-100 font-medium">
                                    {item.label}
                                </span>
                            </Link>
                        ))}
                    </div>

                    <div className="flex flex-col h-full gap-2 justify-end w-full">
                        {settingsExpanded && (
                            <div className="flex flex-col gap-2 w-full items-center rounded-md bg-white  p-2">
                                {username === "admin" && [
                                    <div
                                        key="create-user"
                                        onClick={handleCreateUser}
                                        className="flex gap-4 w-full items-center cursor-pointer rounded-md h-16 bg-gray-200 px-4"
                                    >
                                        <div>Create User</div>
                                        <div className="ml-auto">
                                            <AiOutlineUserAdd size={20} />
                                        </div>
                                    </div>,
                                    <div
                                        key="download-report"
                                        onClick={() => handleReportExport()}
                                        className="flex gap-4 w-full items-center cursor-pointer rounded-md h-16 bg-gray-200 px-4"
                                    >
                                        <div>Download report</div>
                                        <div className="ml-auto">
                                            <AiOutlineDownload size={20} />
                                        </div>
                                    </div>,
                                    <div
                                        key="export-messages"
                                        onClick={() => handleMessagesExport()}
                                        className="flex gap-4 w-full items-center cursor-pointer rounded-md h-16 bg-gray-200 px-4"
                                    >
                                        <div>Export messages</div>
                                        <div className="ml-auto">
                                            <AiOutlineCloudDownload size={20} />
                                        </div>
                                    </div>,
                                    <div
                                        key="export-documents"
                                        onClick={() => handleDocumentsExport()}
                                        className="flex gap-4 w-full items-center cursor-pointer rounded-md h-16 bg-gray-200 px-4"
                                    >
                                        <div>Export documents</div>
                                        <div className="ml-auto">
                                            <AiOutlineCloudDownload size={20} />
                                        </div>
                                    </div>,
                                ]}
                                <div className="flex gap-4 w-full items-center cursor-pointer rounded-md h-16 bg-gray-200 px-4">
                                    <p>Toggle dark mode</p>
                                    <div className="ml-auto">
                                        <ColorModeToggle
                                            onToggle={setIsDarkMode}
                                            on={isDarkMode}
                                        />
                                    </div>
                                </div>
                                <div
                                    onClick={handleLogout}
                                    className="flex gap-4 w-full cursor-pointer items-center rounded-md h-16 text-red-400 font-semibold bg-gray-200 px-4"
                                >
                                    <p>Logout</p>
                                    <div className="ml-auto">
                                        <FiLogOut size={20} />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex gap-4 w-full items-center rounded-md bg-white h-16 bg-opacity-5 px-4">
                            <div className="flex items-center text-gray-900 justify-center bg-gray-100 rounded-sm mt-1 p-2 ">
                                <AiOutlineUser size={20} />
                            </div>
                            <p className="text-gray-300 w-full">{username}</p>
                            <div
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                                className="text-gray-400 p-1 flex cursor-pointer items-center justify-center"
                            >
                                <AiOutlineSetting size={25} />
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </aside>
    );
};
