import React, { useState, useEffect } from "react";
import * as api from "@utils/chat.utils";
import ChatTabList from "./ChatTabList";
import { AiOutlineSend } from "react-icons/ai";
import useToken from "@hooks/token.hooks";
import { Messages } from "./Messages";
import { InputExamples } from "@components/InputExamples";
import { Tab, User, ChatData } from "@customTypes/chat.types";
import Select from "react-select";

export const Chat: React.FC = () => {
    const { token } = useToken();
    const [user, setUser] = useState<User>();
    const [activeTab, setActiveTab] = useState<Tab | null>(null);
    const [message, setMessage] = useState("");
    const [streamedReply, setStreamedReply] = useState("");
    const [tabs, setTabs] = useState<Tab[]>([]);
    const [chats, setChats] = useState<ChatData[]>([]);
    const [waitingForReply, setWaitingForReply] = useState(false);
    const [showExamples, setShowExamples] = useState(true);

    // Options for all available branches
    const branchOptions = [
        { value: "horeca", label: "Horeca" },
        { value: "bouw", label: "Bouw" },
        { value: "detailhandel", label: "Detailhandel" },
    ];
    const [selectedBranch, setSelectedBranch] = useState("horeca");

    const examples = [
        "Wat zijn de minimale aansprakelijkheidsvereisten voor een horecabedrijf volgens de geldende wetten en polissen?",
        "Kun je uitleggen hoe een polis voor bedrijfsonderbrekingen in de horecasector normaal gesproken wordt opgesteld en welke factoren van invloed zijn op de dekking ervan?",
        "Welke specifieke clausules zijn gebruikelijk in CAO's voor horecamedewerkers met betrekking tot arbeidsongevallenverzekeringen en hoe kunnen deze het beste worden geïmplementeerd?",
    ];
    useEffect(() => {
        // Create a new user on component mount
        const createUserAndLoadTabs = async () => {
            if (!token) {
                return;
            }
            const user = await api.getUser();
            if (user) setUser({ username: user.username, id: user.id });
        };
        createUserAndLoadTabs();
    }, []);

    useEffect(() => {
        // Load tabs for the user
        const loadTabsForUser = async () => {
            if (user) {
                const loadedTabs = await api.getChatTabs(user.id);
                setTabs(loadedTabs);
                if (loadedTabs.length > 0) {
                    setActiveTab(loadedTabs[0]);
                }
            }
        };
        loadTabsForUser();
    }, [user]);

    useEffect(() => {
        // Load chats for the active tab
        loadChatsForActiveTab();
    }, [activeTab]);

    /**
     * @param refresh  Whether to refresh the chats for the active tab
     * @returns void
     * @description Loads chats for the active tab
     * @async
     */
    const loadChatsForActiveTab = async () => {
        if (activeTab && user) {
            const loadedChats = await api.getChats(activeTab.id, user.id);
            if (loadedChats.length > 1) {
                setShowExamples(false);
            } else {
                setShowExamples(true);
            }
            setChats(loadedChats);
        }
    };
    /**
     * @returns void
     * @description Loads chat tabs for the user
     * @async
     */
    const loadChatTabs = async () => {
        if (user) {
            const loadedTabs = await api.getChatTabs(user.id);
            setTabs(loadedTabs);
        }
    };

    /**
     * @returns void
     * @description Creates a new chat tab for the user
     * @async
     */
    const handleOpenTabForm = async () => {
        if (!user) {
            return;
        }
        const newTab = await api.createChatTab(user.id, getNewTabName());
        await loadChatTabs(); // Refresh chat tabs after creating a new tab
        setActiveTab(newTab);
    };

    const handleTabClick = (tab: Tab) => {
        if (activeTab && activeTab.id === tab.id) {
            setShowExamples(false);
            return;
        }
        setActiveTab(tab);
        setShowExamples(true);
    };

    const updateChatsWithStream = (tempResponse: string) => {
        // get the last chat in the chats array and update
        // the message key with the streamed response
        setStreamedReply(tempResponse);
    };

    const getNewTabName = () => {
        let newTabName = "";
        if (tabs.length > 0) {
            const tabNum = parseInt(
                tabs[tabs.length - 1].tab_name.split(" ")[1]
            );
            newTabName = `Tab ${tabNum + 1}`;
        } else {
            newTabName = `Tab 1`;
        }
        return newTabName;
    };

    /**
     * @param event  The event object
     * @description Sends a message to the chatbot and adds the reply to the chat
     * @returns void
     * @async
     */
    const handleSendMessage = async (event: any) => {
        event.preventDefault();
        if (!message.trim() || !user) {
            return;
        }

        setWaitingForReply(true);
        setShowExamples(false);
        const msg = message;
        setMessage("");
        // if a message is sent, and there is no active tabs or there's no tabs at all, create a new tab

        if (!activeTab) return;
        else {
            setChats(
                chats
                    .concat({
                        id: 0,
                        message: msg,
                        vote: 0,
                        tab_id: activeTab?.id,
                        certainty: -1,
                    })
                    .concat({
                        id: 0,
                        message: "",
                        vote: 0,
                        tab_id: activeTab?.id,
                        certainty: -1,
                    })
            );
        }

        if (!activeTab) {
            // Create new chat tab, set as active, and send the message
            const newTabName = getNewTabName();

            const newTab = await api.createChatTab(user.id, newTabName);
            await api.sendMessage(
                user.id,
                newTab.id,
                msg,
                selectedBranch,
                updateChatsWithStream
            );
            await api.pollAnswerGenerationFinished(user.id);
            await loadChatTabs(); // Refresh chat tabs after sending a message
            loadChatsForActiveTab();
            setStreamedReply("");
            setShowExamples(true);
        } else {
            await api.sendMessage(
                user.id,
                activeTab.id,
                msg,
                selectedBranch,
                updateChatsWithStream
            );
            await api.pollAnswerGenerationFinished(user.id);
            await loadChatTabs(); // Refresh chat tabs after sending a message
            loadChatsForActiveTab();
            setStreamedReply("");
            setShowExamples(false);
        }

        setWaitingForReply(false);
    };

    /**
     * @param tab  The tab to remove
     * @returns void
     * @description Removes a tab
     * @async
     */
    const handleRemoveTab = async (user_id: number, tab: Tab) => {
        await api.removeTab(user_id, tab.id);
        setTabs((prevTabs) => prevTabs.filter((t) => t.id !== tab.id));
        setActiveTab((currentActiveTab) => {
            if (currentActiveTab && currentActiveTab.id === tab.id) {
                const currentTabIndex = tabs.findIndex(
                    (t) => t.id === currentActiveTab.id
                );
                if (tabs.length > 1) {
                    // Set the active tab to the previous tab (if exists) or the next tab
                    return tabs[
                        currentTabIndex === 0 ? 1 : currentTabIndex - 1
                    ];
                } else {
                    // If there are no tabs left, set activeTab to null
                    return null;
                }
            } else {
                // If the removed tab was not the active tab, keep the current active tab
                return currentActiveTab;
            }
        });
    };

    return (
        <div className="w-full h-full flex flex-col">
            {user && (
                <>
                    <ChatTabList
                        user_id={user.id}
                        tabs={tabs}
                        activeTab={activeTab}
                        onTabClick={handleTabClick}
                        handleRemoveTab={handleRemoveTab}
                        handleOpenTabForm={handleOpenTabForm}
                    />
                    <>
                        {tabs.length > 0 ? (
                            <Messages
                                chats={chats}
                                waitingForReply={waitingForReply}
                                streamedReply={streamedReply}
                            />
                        ) : (
                            <InputExamples />
                        )}
                        {tabs.length === 0 ? (
                            <h1 className="dark:text-gray-200 text-gray-900 w-full text-center p-4">
                                Open een chat tab door op het "+" plus icoon te
                                klikken, linksboven in het scherm.
                            </h1>
                        ) : (
                            <>
                                {showExamples && (
                                    <div className="py-4 px-4 w-full max-w-4xl mx-auto">
                                        {examples.map((example, index) => (
                                            <div
                                                key={index}
                                                className="mb-2 py-2 px-4 border dark:bg-tertiaryDark border-gray-300 dark:text-gray-200 dark:border-gray-600 focus:outline-none focus:ring-0"
                                            >
                                                {example}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <form onSubmit={handleSendMessage}>
                                    <div className="py-4 px-4 w-full max-w-4xl mx-auto flex">
                                        {/* Select the branch for each message */}
                                        <Select
                                            unstyled
                                            defaultValue={branchOptions[0]}
                                            isSearchable={false}
                                            onChange={(e) => {
                                                setSelectedBranch(
                                                    e ? e.value : ""
                                                );
                                            }}
                                            options={branchOptions}
                                            menuPlacement="top"
                                            classNames={{
                                                control: (state) =>
                                                    "border-t border-l border-b rounded-l-md dark:bg-tertiaryDark border-gray-300 dark:border-gray-600 py-2 px-4 border-1 focus:outline-none focus:ring-0 dark:text-gray-200",
                                                menu: (state) =>
                                                    "border border-gray-200 rounded-md p-2 bg-white py-0 mb-1",
                                                option: () =>
                                                    "cursor-pointer rounded-md h-7 bg-gray-200 px-3 my-2 hover:bg-gray-300",
                                            }}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    width: "max-content",
                                                }),
                                                control: (css) => ({
                                                    ...css,
                                                    height: "100%",
                                                }),
                                                dropdownIndicator: (css) => ({
                                                    ...css,
                                                    rotate: "180deg",
                                                }),
                                            }}
                                        />

                                        <input
                                            type="text"
                                            value={message}
                                            disabled={waitingForReply}
                                            onChange={(event) =>
                                                setMessage(event.target.value)
                                            }
                                            placeholder="Type a message..."
                                            className="flex-grow border dark:bg-tertiaryDark border-gray-300 dark:text-gray-200 dark:border-gray-600 py-2 px-4 border-1 focus:outline-none focus:ring-0"
                                        />
                                        <button
                                            type="submit"
                                            className="bg-secondary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-md"
                                        >
                                            <AiOutlineSend />
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </>
                </>
            )}
        </div>
    );
};
