import axios from "@includes/axios";

export const postMessage = async (
    message: string,
    first: number,
    title: string,
    summary: string,
    keywords: string[],
): Promise<string> => {
    const response = await axios.post("/chat", {
        message: message,
        first: first,
        title: title,
        summary: summary,
        keywords: keywords,
    });

    const reply: string = response.data.reply;

    return reply;
};

export const createUser = async () => {
    try {
        const response = await axios.post("/create_user");
        return response.data;
    } catch (error) {
        console.error("Error creating user:", error);
        return null;
    }
};

export const getUser = async () => {
    try {
        const response = await axios.get(`/users/check_session`);
        return response.data;
    } catch (error) {
        console.error("Error getting user:", error);
        return null;
    }
};

export const getDocuments = async () => {
    try {
        const response = await axios.get("/documents");
        return response.data;
    } catch (error) {
        console.error("Error loading the documents:", error);
        return [];
    }
};

export const getChatTabs = async (userId: number) => {
    try {
        const response = await axios.get(`/chat/chat_tabs/${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error loading chat tabs:", error);
        return [];
    }
};

export const getChats = async (tabId: number, userId: number) => {
    try {
        const response = await axios.get(
            `/chat/chat_messages/${tabId}/${userId}`,
        );
        return response.data;
    } catch (error) {
        console.error("Error loading chats:", error);
        return [];
    }
};

const saveAnswer = async (userId: number) => {
    try {
        await axios.post(`/chat/save_answer/${userId}`);
    } catch (error) {
        console.error("Error saving answer to database:", error);
    }
};

export const pollAnswerGenerationFinished = async (userId: number) => {
    while (true) {
        try {
            const response = await axios.get(
                `/chat/check_if_answered/${userId}`,
            );
            if (response.data.finished) {
                saveAnswer(userId);
                break;
            }
            await new Promise((resolve) => setTimeout(resolve, 5000));
        } catch (error) {
            console.error("Error polling answer generation:", error);
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }
};

export const sendMessage = async (
    userId: number,
    tabId: number,
    message: string,
    branch: string,
    callback: (reply: string) => void,
) => {
    try {
        const token = localStorage.getItem("token");
        let tempReply = "";
        const options: RequestInit = {
            method: "POST",
            body: JSON.stringify({ message: message, branch: branch }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(
            `/api/chat/send_message/${userId}/${tabId}`,
            options,
        );
        if (!response.body) {
            return;
        }
        let decoder = new TextDecoderStream();
        const reader = response.body.pipeThrough(decoder).getReader();

        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }
            tempReply += value;
            callback(tempReply.toString());
        }
    } catch (error) {
        console.error("Error sending message:", error);
    }
};

export const removeTab = async (userId: number, tabId: number) => {
    try {
        await axios.delete(`/chat/remove_tab/${userId}/${tabId}`);
    } catch (error) {
        console.error("Error removing tab:", error);
    }
};

/**
 * @description Creates a new chat tab.
 * @param userId  The user id of the user creating the chat tab.
 * @param tabName The name of the chat tab.
 * @returns {Promise<ChatTab>} The created chat tab.
 * @throws Error
 * @async
 */
export const createChatTab = async (userId: number, tabName: string) => {
    try {
        const response = await axios.post(`/chat/create_chat_tab`, {
            user_id: userId,
            tab_name: tabName,
        });
        return response.data;
    } catch (error) {
        console.error("Error creating chat tab:", error);
        return null;
    }
};
