import { useEffect, useState } from "react";
import { SummarisedArticle } from "@customTypes/timeline.types";
import React from "react";
import { Timeline } from "@components/Timeline/Timeline"; // Import the new Timeline component
import { groupTimelineData, fetchTimelineData } from "@utils/timeline.utils";

export const TimelineMain: React.FC = () => {
    const [collapsedYears, setCollapsedYears] = useState<number[]>([]);
    const [activeCategory, setActiveCategory] = useState<string>("cafe");
    const [, setTimelineData] = useState<SummarisedArticle[]>([]); // State for tab timeline data

    // create a state which stores a dict of timeline data with the category as key
    const [timelineDataDict, setTimelineDataDict] = useState<{
        [category: string]: SummarisedArticle[];
    }>({});

    const categories = {
        cafe: "Cafe",
        restaurant: "Restaurant",
        hotel: "Hotel",
        cafetarias_fastfood: "Cafetarias & Fastfood",
    };

    useEffect(() => {
        if (Object.keys(timelineDataDict).length > 0) {
            return;
        }
        const fetchTimelineDataAsync = async () => {
            for (const [key] of Object.entries(categories)) {
                const timelineData = await fetchTimelineData(key.toLowerCase());
                setTimelineDataDict((prevTimelineDataDict) => ({
                    ...prevTimelineDataDict,
                    [key]: timelineData,
                }));
                setTimelineData(timelineDataDict[activeCategory]);
            }
        };
        fetchTimelineDataAsync();
    }, []);

    /**
     * @param year  The year to toggle collapse for
     * @returns void
     * @description Toggles the collapse state of a year
     */
    const toggleYearCollapse = (year: number) => {
        if (collapsedYears.includes(year)) {
            setCollapsedYears(collapsedYears.filter((y) => y !== year));
        } else {
            setCollapsedYears([...collapsedYears, year]);
        }
    };

    return (
        timelineDataDict[activeCategory] && (
            <div className="max-h-screen h-screen flex flex-col items-center bg-white ">
                <div className="h-full dark:text-gray-200">
                    <div className="h-full flex flex-col ">
                        <div className="overflow-y-auto flex-grow-0 w-full h-full dark:bg-primaryDark dark:text-gray-200">
                            <Timeline
                                groupedTimelineData={groupTimelineData(
                                    timelineDataDict[activeCategory],
                                )}
                                collapsedYears={collapsedYears}
                                toggleYearCollapse={toggleYearCollapse}
                            />
                        </div>
                        <div className="p-6 w-full bg-white dark:bg-primaryDark border-t-[1px] border-gray-200 dark:border-gray-600 flex items-center justify-center">
                            <div className="w-2/3 flex gap-4 justify-center">
                                {Object.entries(categories).map(
                                    ([key, value]) => (
                                        <button
                                            className={`text-gray-700 dark:text-gray-100 px-4 py-2 border-[1px] rounded-md dark:border-gray-500 border-gray-500 ${
                                                key === activeCategory
                                                    ? " dark:bg-gray-900"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                setActiveCategory(key);
                                            }}
                                        >
                                            {value}
                                        </button>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};
