import { useEffect, useState } from "react";
import { ChatData } from "@customTypes/chat.types";
import React from "react";
import { Chat } from "@components/Chat/Chat";

export const ChatMain: React.FC = () => {
    const [chats, setChats] = useState<{ [tabIndex: number]: ChatData[] }>({});

    return (
        <div className="w-full h-full md:flex overflow-y-hidden justify-center bg-white dark:bg-primaryDark">
            <Chat />
        </div>
    );
};
