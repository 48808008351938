import React from "react";

export const TimelineArticle: React.FC<{
    summary: string;
    title: string;
    date: string;
    keywords: string[];
}> = ({ summary, title, date, keywords }) => {
    const id = Math.random().toString(36).substring(7);

    return (
        <React.Fragment key={date + id}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <div className="flex">
                        <h1 className="font-semibold text-xl w-full">
                            {title}
                        </h1>
                        {/* <button className="border-2 flex h-1/2 items-center gap-2 rounded-md px-4 py-1">
                            Chat <BsChatRight />
                        </button> */}
                    </div>
                    <p className="text-sm text-slate-500 dark:text-gray-400">
                        Due by: {date}
                    </p>
                </div>
                <p>{summary}</p>
                <div className="flex gap-4">
                    {keywords.map((keyword) => (
                        <span
                            className="text-sm font-semibold py-1 rounded-full mr-2 text-topic"
                            key={keyword}
                        >
                            {keyword.charAt(0).toUpperCase() + keyword.slice(1)}
                        </span>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};
