import React from "react";
import axios from "@includes/axios";

export const getCertaintyColor = (certainty: number): string => {
    if (certainty === 0) {
        return "border-2 border-red-500";
    } else if (certainty === 1) {
        return "border-2 border-yellow-500";
    } else if (certainty === 2) {
        return "border-2 border-green-500";
    } else {
        return "";
    }
};

// Function to render messages with clickable links
export const renderMessageWithLinks = (message: string): JSX.Element => {
    // RegEx for recognizing URLs (both "http://" and "https://")
    // that start at the beginning of a line or after a whitespace
    const linkRegex: RegExp = /source_link:([^\s]+)/g;

    const messageWithLinks: JSX.Element[] = [];
    let match: RegExpExecArray | null;
    let lastIndex: number = 0;

    while ((match = linkRegex.exec(message))) {
        const link: string = match[0].replace("source_link:/", "").trim();
        const startIndex: number = match.index;
        const endIndex: number = linkRegex.lastIndex;

        // Extract text before the link and add it to the messageWithLinks array
        if (startIndex > lastIndex) {
            const textBeforeLink = message.slice(lastIndex, startIndex);
            messageWithLinks.push(
                <React.Fragment key={`text-${startIndex}`}>
                    {textBeforeLink}
                </React.Fragment>,
            );
        }

        // Add the link as a hyperlink
        messageWithLinks.push(
            <React.Fragment key={`link-${startIndex}`}>
                {startIndex !== 0 && " "}{" "}
                {/* Add a space if startIndex is not 0 */}
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                >
                    hier
                </a>
            </React.Fragment>,
        );

        lastIndex = endIndex;
    }

    // Add any remaining text after the last link
    if (lastIndex < message.length) {
        messageWithLinks.push(
            <React.Fragment key={`text-${lastIndex}`}>
                {message.slice(lastIndex)}
            </React.Fragment>,
        );
    }

    return <>{messageWithLinks}</>;
};

// function to send upvote to database
export const sendVote = async (
    value: number,
    message_id: number,
    tab_id?: number,
    reason?: string,
) => {
    try {
        const response = await axios.post(`/chat/sendVote/${value}`, {
            message_id: message_id,
            tab_id: tab_id,
            reason: reason,
        });
        return response.data;
    } catch (error) {
        console.error("Error sending vote to database: ", error);
    }
};
