import { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { sidebarItems } from "@utils/navigation.utils";

export const Navbar: React.FC = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation().pathname;

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="dark:bg-secondaryDark sticky top-0 w-full bg-white flex md:hidden items-center justify-between py-2">
            <div>
                <div className="relative">
                    <button className="nav-item px-4" onClick={toggleDropdown}>
                        <IoMdArrowDropdown
                            size={20}
                            className={`mr-1 ${
                                isDropdownOpen ? "transform rotate-180" : ""
                            }`}
                        />
                    </button>
                    {isDropdownOpen && (
                        <div className="absolute mt-2 w-screen dark:bg-secondaryDark shadow-lg">
                            {sidebarItems.map((item, index) => (
                                <Link
                                    key={index}
                                    className={
                                        location === item.path
                                            ? "nav-item-active"
                                            : "nav-item"
                                    }
                                    to={item.path}
                                    onClick={() => setIsDropdownOpen(false)}
                                >
                                    {item.icon}
                                    <span className="mx-2 text-sm text-gray-100 font-medium">
                                        {item.label}
                                    </span>
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};
