import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "@includes/axios";

const CreateUser: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("user");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        try {
            // Ensure the token is included in the request
            const token = localStorage.getItem("token"); // Adjust based on where you store the token
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.post(
                "/users/create",
                {
                    username: username,
                    password: password,
                },
                config
            );

            setSuccess(response.data.message);
            setUsername("");
            setPassword("");
            setRole("user");
        } catch (err: any) {
            if (err.response) {
                setError(err.response.data.message || "User already exists");
            } else {
                setError("User already exists");
            }
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
                <h2 className="text-2xl font-bold mb-6 text-center">
                    Create New User
                </h2>
                {error && (
                    <p className="text-red-500 text-center mb-4">{error}</p>
                )}
                {success && (
                    <p className="text-green-500 text-center mb-4">{success}</p>
                )}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label
                            htmlFor="username"
                            className="block mb-1 font-medium"
                        >
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="password"
                            className="block mb-1 font-medium"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="role"
                            className="block mb-1 font-medium"
                        >
                            Role
                        </label>
                        <select
                            id="role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md"
                        >
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
                    >
                        Create User
                    </button>
                </form>
                <button
                    onClick={() => navigate("/")}
                    className="w-full mt-4 bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400 transition duration-300"
                >
                    Back to Dashboard
                </button>
            </div>
        </div>
    );
};

export default CreateUser;
