import axios from "@includes/axios";

/**
 * @description retrieves the username of the current user, based on the JWT token.
 * @throws Error
 * @returns {string} The username of the current user.
 */
export const getUsername = async () => {
    try {
        const response = await axios.get(`/users/check_session`);
        return response.data.username;
    } catch (error) {
        console.error("Error getting user:", error);
        return null;
    }
};

/**
 * @description Logs the user out.
 * @throws Error
 */
export const logout = async () => {
    try {
        await axios.post(`/users/logout`);
    } catch (error) {
        console.error("Error logging out:", error);
    }
};

/**
 * @description Logs the user out client side.
 * @throws Error
 * @returns {void}
 * @param {void}
 */
export const clientSideLogout = () => {
    // Clear the JWT token from cookies
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Clear the JWT token from local storage (optional)
    localStorage.removeItem("token");

    // redirect to login page
    window.location.href = "/login";
};
