import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./views/Layout";
import { TimelineMain } from "@views/pages/TimelineMain";
import { ChatMain } from "@views/pages/ChatMain";
import { Login } from "@views/pages/Login";
import { useEffect, useState } from "react";
import useToken from "@hooks/token.hooks";
import axios from "@includes/axios";
import { DocumentsMain } from "@views/pages/DocumentsMain";
import CreateUser from "./components/CreateUser";

const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
    return isAuthenticated ? (
        <Layout />
    ) : (
        <Navigate to={{ pathname: "/login" }} />
    );
};

function App() {
    const { setToken } = useToken();
    const [, setUser] = useState<string>();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
        const storedLoginState = localStorage.getItem("isLoggedIn");
        return storedLoginState ? JSON.parse(storedLoginState) : false;
    });

    useEffect(() => {
        localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
    }, [isLoggedIn]);

    const handleLogin = async (email: string, access_token: string) => {
        const response = await axios
            .post("/users/login", {
                username: email,
                password: access_token,
            })
            .then((res) => {
                if (res === undefined) return false;
                setIsLoggedIn(true);
                setToken(res.data.access_token);
                setUser(email);
                window.location.href = "/chat";
                return true;
            })
            .catch((res) => {
                return false;
            });

        return response;
    };

    useEffect(() => {
        const checkSession = async () => {
            await axios.get("/users/check_session").then((res) => {
                if (res === undefined) {
                    setIsLoggedIn(false);
                }
            });
        };

        checkSession();
    }, []);

    return (
        <BrowserRouter>
            {!isLoggedIn && <Navigate to="/login" />}
            <Routes>
                <Route
                    path="/"
                    element={
                        <PrivateRoute
                            isAuthenticated={isLoggedIn}
                            component={<Layout />}
                        />
                    }
                >
                    <Route index element={<ChatMain />} />
                    <Route path="/timeline" element={<TimelineMain />} />
                    <Route path="/chat" element={<ChatMain />} />
                    <Route path="/documents" element={<DocumentsMain />} />
                    <Route path="/create-user" element={<CreateUser />} />{" "}
                    {/* New route for CreateUser */}
                </Route>
                <Route
                    path="/login"
                    element={<Login handleLogin={handleLogin} />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
