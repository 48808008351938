import { SideNav } from "@components/SideNav";
import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { Navbar } from "@components/Navbar";
import { getUsername } from "@utils/auth.utils";
import {
    AiFillCheckCircle,
    AiFillCloseSquare,
    AiFillWarning,
} from "react-icons/ai";

export const Layout: React.FC = () => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
    const [username, setUsername] = useState<string>("");
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        // Check if the user has visited the page before
        const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");

        if (!hasVisitedBefore) {
            // If not visited before, show the popup and set the flag in localStorage
            setShowPopup(true);
            localStorage.setItem("hasVisitedBefore", "true");
        }
    }, []);

    useEffect(() => {
        if (username !== "") return;
        getUsername().then((username) => setUsername(username));
    }, []);

    return (
        <div
            className={`flex md:flex-row flex-col h-screen w-screen ${
                isDarkMode && "dark"
            }`}
        >
            <>
                <Navbar />
                <div className="bg-white hidden md:flex">
                    <SideNav
                        isDarkMode={isDarkMode}
                        setIsDarkMode={setIsDarkMode}
                        username={username}
                    />
                </div>
                <Outlet />
                {
                    // check if key token is present in cookies
                    <div>
                        {showPopup && (
                            <div className="fixed w-screen h-screen inset-0 bg-black bg-opacity-60 text-gray-700 flex justify-center items-center">
                                <div className="bg-white rounded-lg p-8 max-w-2xl flex flex-col gap-4">
                                    <div className="flex w-full">
                                        <h1 className="font-semibold text-2xl">
                                            De Goudse Regel App
                                        </h1>
                                        <AiFillCloseSquare
                                            className="ml-auto cursor-pointer"
                                            size={35}
                                            color="#eb513d"
                                            onClick={() => setShowPopup(false)}
                                        />
                                    </div>
                                    <div className="w-full border-b-2"></div>
                                    <div className="flex items-center gap-3">
                                        <AiFillWarning
                                            size={25}
                                            color="#ff8833"
                                        />
                                        <h2 className="text-lg font-medium">
                                            Hou rekening met het volgende
                                        </h2>
                                    </div>

                                    <div>
                                        <h3 className="text-md font-medium">
                                            Het openen van tabs
                                        </h3>
                                        <p className="text-sm">
                                            Zorg ervoor dat u{" "}
                                            <span className="font-bold">
                                                geen nieuwe tab opent
                                            </span>{" "}
                                            of{" "}
                                            <span className="font-bold">
                                                de pagina herlaadt
                                            </span>{" "}
                                            wanneer de chatbot bezig is met het
                                            formuleren van een antwoord.
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className="text-md font-medium">
                                            Het sluiten van tabs
                                        </h3>
                                        <p className="text-sm">
                                            Zorg ervoor dat u{" "}
                                            <span className="font-bold">
                                                een tab niet sluit
                                            </span>{" "}
                                            wanneer de chatbot bezig is met het
                                            formuleren van een antwoord.
                                        </p>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <AiFillCheckCircle
                                            size={25}
                                            color="#489c5a"
                                        />
                                        <h2 className="text-lg font-medium">
                                            Features
                                        </h2>
                                    </div>
                                    <div>
                                        <h3 className="text-md font-medium">
                                            Het geven van feedback
                                        </h3>
                                        <p className="text-sm">
                                            U kunt{" "}
                                            <span className="font-bold">
                                                feedback geven
                                            </span>{" "}
                                            op een antwoord van de chatbot door
                                            te klikken op het{" "}
                                            <span className="font-bold">
                                                duimpje omhoog
                                            </span>{" "}
                                            of{" "}
                                            <span className="font-bold">
                                                duimpje omlaag
                                            </span>{" "}
                                            rechts van het antwoord gegeven door
                                            de chatbot.
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className="text-md font-medium">
                                            Instellingen
                                        </h3>
                                        <p className="text-sm">
                                            Door op het{" "}
                                            <span className="font-bold">
                                                tandwieltje linksonder in de
                                                pagina
                                            </span>{" "}
                                            te klikken, krijgt u een overzicht
                                            van verschillende instellingen zoals
                                            de mogelijkheid om uit te loggen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </>
        </div>
    );
};
