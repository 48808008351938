import React, { useState, useEffect } from "react";
import * as api from "@utils/chat.utils";
import {
    AiOutlineSend,
    AiFillCloseSquare,
    AiOutlineFileAdd,
} from "react-icons/ai";
import useToken from "@hooks/token.hooks";
import { DocumentsData } from "@customTypes/documents.types";
import { DocumentsBranch } from "./DocumentsBranch";
import { DocumentLink } from "./DocumentLink";
import axios from "@includes/axios";

// The directories on the backend are in english, so we need to translate it to Dutch
const DOC_CATEGORY_MAP: { [docType: string]: string } = {
    CAO: "CAO",
    laws: "Wet- en regelgeving",
    policies: "Polisvoorwaarden",
};

export const Documents: React.FC = () => {
    const [documentsLoaded, setDocumentsLoaded] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState<string>("");
    const [documentsDict, setDocumentsDict] = useState<DocumentsData>({});

    // Suggest a new document
    const [isSuggesting, setIsSuggesting] = useState(false);
    const [suggestedDocumentName, setSuggestedDocumentName] = useState("");
    const [suggestedDocumentUrl, setSuggestedDocumentUrl] = useState("");

    const sendDocumentProposal = async (
        documentName: string,
        documentUrl: string,
    ) => {
        try {
            const response = await axios.post("documents/suggest", {
                suggestion: documentName,
                url: documentUrl,
            });
            return response.data;
        } catch (error) {
            console.error(
                "Error sending document proposal to database: ",
                error,
            );
        }
    };

    // Capitilize the first letter
    const capitalize = <T extends string>(s: T) =>
        (s[0].toUpperCase() + s.slice(1)) as Capitalize<typeof s>;

    const submitDocumentProposal = (close = false) => {
        setSuggestedDocumentName("");
        setSuggestedDocumentUrl("");
        if (close) return;

        if (suggestedDocumentName.length == 0) return;

        sendDocumentProposal(suggestedDocumentName, suggestedDocumentUrl);
    };

    useEffect(() => {
        // Load documents from the API
        const loadDocuments = async () => {
            const docs = await api.getDocuments();
            setSelectedBranch(Object.keys(docs["data"])[0]);
            setDocumentsDict(docs["data"]);
            setDocumentsLoaded(true);
        };

        loadDocuments();
    }, []);

    return (
        <div className="w-full h-full flex flex-col">
            {/* Modal window to add a new document proposal */}
            {isSuggesting ? (
                <div className="fixed w-screen h-screen inset-0 bg-black bg-opacity-60 flex justify-center items-center">
                    <div className="bg-white w-1/4 dark:bg-secondaryDark rounded-md p-4 flex flex-col gap-4">
                        {
                            <div className="flex flex-col gap-4">
                                <div className="flex w-full">
                                    <label
                                        className="text-gray-700 w-full dark:text-gray-200"
                                        htmlFor="reason"
                                    >
                                        Nieuw documentvoorstel
                                    </label>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setIsSuggesting(false);
                                            submitDocumentProposal(true);
                                        }}
                                    >
                                        <AiFillCloseSquare
                                            size={24}
                                            color="#FF6961"
                                        />
                                    </div>
                                </div>
                                <input
                                    className="border-2 rounded-md w-full bg-secondaryDark focus:ring-gray-400 ring-1 ring-gray-600 focus:ring-2 p-4 text-gray-200 border-none outline-none"
                                    type="text"
                                    name="new-document-name"
                                    id="new-document-name"
                                    placeholder="Documentnaam of beschrijving"
                                    value={suggestedDocumentName}
                                    onChange={(e) => {
                                        setSuggestedDocumentName(
                                            e.target.value,
                                        );
                                    }}
                                />
                                <input
                                    className="border-2 rounded-md w-full bg-secondaryDark focus:ring-gray-400 ring-1 ring-gray-600 focus:ring-2 p-4 text-gray-200 border-none outline-none"
                                    type="text"
                                    name="new-document-url"
                                    id="new-document-url"
                                    placeholder="Documentlink (optioneel)"
                                    value={suggestedDocumentUrl}
                                    onChange={(e) => {
                                        setSuggestedDocumentUrl(e.target.value);
                                    }}
                                />
                            </div>
                        }

                        <button
                            className=" text-white bg-secondary rounded-md py-2 px-4"
                            onClick={() => {
                                setIsSuggesting(false);
                                submitDocumentProposal();
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            ) : null}

            <h1 className="dark:text-gray-200 text-gray-900 w-full text-center p-4">
                Bron documenten
            </h1>

            <div className="space-y-10 flex-grow-0 h-full p-4 mx-auto justify-right p-4 w-2/3 overflow-y-scroll  ">
                <button
                    className="flex space-x-3 text-gray-700 dark:text-gray-100 px-4 py-2 border-[1px] rounded-md dark:border-gray-500 border-gray-500"
                    onClick={() => setIsSuggesting(true)}
                >
                    <AiOutlineFileAdd size={24} color="#FFF" />
                    <span className="text-m">Stel een nieuw document voor</span>
                </button>

                {
                    // Render all the documents within each doc category
                    documentsLoaded
                        ? Object.keys(documentsDict[selectedBranch]).map(
                              (docType) => {
                                  return (
                                      <DocumentsBranch
                                          key={
                                              selectedBranch +
                                              "-" +
                                              docType +
                                              "-component"
                                          }
                                          name={capitalize(selectedBranch)}
                                          type={DOC_CATEGORY_MAP[docType]}
                                      >
                                          {documentsDict[selectedBranch][
                                              docType
                                          ].map((doc) => (
                                              <DocumentLink
                                                  title={
                                                      doc["name"].length > 0
                                                          ? doc["name"]
                                                          : "? Filename not found"
                                                  }
                                                  href={doc["path"]}
                                                  key={
                                                      doc["path"] + "-component"
                                                  }
                                              />
                                          ))}
                                      </DocumentsBranch>
                                  );
                              },
                          )
                        : []
                }
            </div>

            <div className="p-6 w-full bg-white dark:bg-primaryDark border-t-[1px] border-gray-200 dark:border-gray-600 flex items-center justify-center">
                <div className="w-2/3 flex gap-4 justify-center">
                    {[
                        // General button first
                        <button
                            key="general-btn-component"
                            className={`flex text-gray-700 dark:text-gray-100 px-4 py-2 border-[1px] rounded-md dark:border-gray-500 border-gray-500 ${
                                "general" === selectedBranch
                                    ? " dark:bg-gray-900"
                                    : ""
                            }`}
                            onClick={() => setSelectedBranch("general")}
                        >
                            <span className="text-m">Generiek</span>
                        </button>,

                        // Render buttons for all the branches
                        Object.keys(documentsDict).map((branch) => {
                            if (branch !== "general")
                                return (
                                    <button
                                        key={branch + "-btn-component"}
                                        className={`flex text-gray-700 dark:text-gray-100 px-4 py-2 border-[1px] rounded-md dark:border-gray-500 border-gray-500 ${
                                            branch === selectedBranch
                                                ? " dark:bg-gray-900"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setSelectedBranch(branch)
                                        }
                                    >
                                        <span className="text-m">
                                            {capitalize(branch)}
                                        </span>
                                    </button>
                                );
                        }),
                    ]}
                </div>
            </div>
        </div>
    );
};
