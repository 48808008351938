import { useState } from "react";
import { BsArrowRight, BsTypeH1 } from "react-icons/bs";

interface LoginProps {
    handleLogin: (email: string, access_token: string) => Promise<boolean>;
}

export const Login: React.FC<LoginProps> = ({ handleLogin }) => {
    const [accessToken, setAccessToken] = useState("");
    const [email, setEmail] = useState("");
    const [complete, setComplete] = useState(true);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Perform form validation and send login request to the back-end
        const loginResult: Promise<boolean> = handleLogin(email, accessToken);
        loginResult.then((res) => setComplete(res));
    };

    return (
        <div className="dark:bg-primaryDark h-screen w-screen flex justify-center">
            <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 items-center justify-center "
            >
                <input
                    className="w-full px-4 py-3 border-[1px] font-light rounded-md"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Username"
                />
                <input
                    className="w-full px-4 py-3 font-light border-[1px] rounded-md"
                    type="password"
                    value={accessToken}
                    onChange={(e) => setAccessToken(e.target.value)}
                    placeholder="Access Token"
                />
                <button
                    className="w-full px-4 py-3 bg-secondary text-white rounded-md flex items-center"
                    type="submit"
                >
                    <span className="w-full text-left">Sign in</span>
                    <BsArrowRight size={25} />
                </button>
                {!complete && (
                    <div className="w-full  rounded-md py-2 px-4 bg-red-500 bg-opacity-10 border-red-300 border-2 text-red-500 font-semibold ">
                        Invalid credentials
                    </div>
                )}
            </form>
        </div>
    );
};
