import { IconType } from "react-icons";
import {
    AiOutlineFieldTime,
    AiOutlineMessage,
    AiOutlineFolderOpen,
} from "react-icons/ai";
const logo = require("../assets/img/logo.svg").default as string;

export const sidebarItems: {
    path: string;
    icon: JSX.Element;
    label: string;
}[] = [
    {
        path: "/chat",
        icon: <AiOutlineMessage size={25} />,
        label: "Chat",
    },
    {
        path: "/documents",
        icon: <AiOutlineFolderOpen size={25} />,
        label: "Documenten",
    },
    /*
    {
        path: "/timeline",
        icon: <AiOutlineFieldTime size={25} />,
        label: "Tijdlijn",
    },
    */
];
