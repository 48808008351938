import React from "react";
import { IoIosClose } from "react-icons/io";

interface Tab {
    id: number;
    tab_name: string;
}

interface ChatTabListProps {
    user_id: number;
    tabs: Tab[];
    activeTab: Tab | null;
    onTabClick: (tab: Tab) => void;
    handleRemoveTab: (user_id: number, tab: Tab) => void;
    handleOpenTabForm: (tabName: string) => void;
}

const ChatTabList: React.FC<ChatTabListProps> = ({
    user_id,
    tabs,
    activeTab,
    onTabClick,
    handleRemoveTab,
    handleOpenTabForm,
}) => {
    return (
        <div className="flex p-4 gap-4">
            <button
                onClick={() => handleOpenTabForm("Tab " + (tabs.length + 1))}
                className="flex items-center px-4 py-2 justify-center rounded-md dark:text-gray-200 text-white border-[1px] border-gray-500 bg-primaryDark"
            >
                <p className="truncate w-full font-bold">+</p>
            </button>
            <div className=" w-full flex gap-3 overflow-x-scroll no-scrollbar">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        onClick={() => onTabClick(tab)}
                        className={`py-2 px-4 rounded-md border-gray-300 dark:text-gray-200 dark:border-gray-700 border-[1px] text-left flex items-center ${
                            activeTab && activeTab.id === tab.id
                                ? "bg-gray-200 dark:bg-secondaryDark w-full"
                                : "max-w-[200px]"
                        }`}
                    >
                        <p className="truncate w-full">{tab.tab_name}</p>
                        <a onClick={() => handleRemoveTab(user_id, tab)}>
                            <IoIosClose size={30} />
                        </a>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ChatTabList;
