import { Documents } from "@components/Documents/Documents";
import { useEffect, useState } from "react";
import React from "react";

export const DocumentsMain: React.FC = () => {
    useEffect(() => {}, []);

    return (
        <div className="w-full h-full md:flex overflow-y-hidden justify-center bg-white dark:bg-primaryDark">
            <Documents />
        </div>
    );
};
