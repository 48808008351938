import axios from "@includes/axios";
import { SummarisedArticle } from "@customTypes/timeline.types";
import { GroupedTimelineData } from "@customTypes/timeline.types";

/**
 * @param apiEndpoint: string
 * @returns Promise<TimelineData>
 * @throws Error
 * @description Fetches timeline data from the API.
 */
export const fetchTimelineData = async (company: string) => {
    try {
        const response = await axios.get("/timeline/get-timeline/" + company);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const groupTimelineData = (
    timelineData: SummarisedArticle[],
): GroupedTimelineData => {
    return timelineData.reduce<GroupedTimelineData>(
        (acc, { summary, title, date, keywords }) => {
            let year = date.split("/")[2];
            if (year === undefined) {
                year = "Uncertain";
            }
            if (acc[year]) {
                acc[year].push({
                    summary,
                    title,
                    date,
                    keywords,
                });
            } else {
                acc[year] = [
                    {
                        summary,
                        title,
                        date,
                        keywords,
                    },
                ];
            }
            return acc;
        },
        {},
    );
};
