import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "./styles/menu.css";
import "./styles/sidenav.css";
import "./styles/global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(<App />);

reportWebVitals();
