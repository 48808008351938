import {
    BsExclamationTriangle,
    BsLightningCharge,
    BsSun,
} from "react-icons/bs";

/**
 * @description Data for the input examples page
 * @type {Object}
 * @property {string[]} Voorbeelden - Examples of questions that can be asked
 * @property {string[]} Mogelijkheden - Possible features of the chatbot
 * @property {string[]} Limitaties - Limitations of the chatbot
 */
const infoData: { [key: string]: string[] } = {
    Voorbeelden: [
        "Wat zijn de minimale aansprakelijkheidsvereisten voor een horecabedrijf volgens de geldende wetten en polissen?",
        "Kun je uitleggen hoe een polis voor bedrijfsonderbrekingen in de horecasector normaal gesproken wordt opgesteld en welke factoren van invloed zijn op de dekking ervan?",
        "Welke specifieke clausules zijn gebruikelijk in CAO's voor horecamedewerkers met betrekking tot arbeidsongevallenverzekeringen en hoe kunnen deze het beste worden geïmplementeerd?",
    ],
    Mogelijkheden: [
        "Deze chatbot onthoudt wat de gebruiker eerder in het gesprek heeft gezegd.",
        "Deze chatbot staat de gebruiker toe om vervolgvragen te stellen en correcties te geven.",
        "Gevoelige data wordt geanonimiseerd.",
    ],
    Limitaties: [
        "Deze chatbot is beperkt tot de informatie waarop hij is getraind, namelijk documenten van De Goudse.",
        "Deze chatbot is niet in staat om vragen te beantwoorden die buiten het domein van de verzekeringen vallen.",
    ],
};

/**
 *
 * @param key  The key of the infoData object
 * @description Returns the icon for the given key
 * @returns {JSX.Element} The icon for the given key
 */
const getIcon = (key: string) => {
    switch (key) {
        case "Voorbeelden":
            return <BsSun size={25} />;
        case "Mogelijkheden":
            return <BsLightningCharge size={25} />;
        case "Limitaties":
            return <BsExclamationTriangle size={25} />;
    }
};

export const InputExamples: React.FC = () => {
    return (
        <div className="flex h-full overflow-y-auto w-full max-w-5xl pt-8 mx-auto gap-4 items-center p-4">
            <div className="flex lg:flex-row flex-col gap-4 h-full lg:h-fit">
                {Object.keys(infoData).map((key, index) => {
                    return (
                        <div key={index} className="flex flex-col w-full ">
                            <div className="w-full flex items-center justify-center dark:text-[rgba(236,236,241)] ">
                                {getIcon(key)}
                            </div>
                            <div className="text-xl text-center p-4 dark:text-[rgba(236,236,241)] ">
                                {key}
                            </div>
                            <div className="flex flex-col gap-4">
                                {infoData[key].map((item, index) => {
                                    return (
                                        <div
                                            className="bg-gray-100 dark:bg-tertiaryDark dark:text-[rgba(236,236,241)] rounded-md py-4 px-6"
                                            key={index}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
